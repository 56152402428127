.search-filter-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 40px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(36, 37, 108, 0.1);
  width: 260px;
  flex-shrink: 0;
  .product-filter {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .filter-title {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }

  .tag-filter {
    display: flex;
    flex-direction: column;
    gap: 13px;

    .tag-buttons {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      .tag-button {
        display: inline-block;
        padding: 4px;
        border-radius: 4px;
        background-color: #f0f0f0;
        cursor: pointer;
        user-select: none;
        transition: background-color 0.3s ease;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 160%;
        color: #353ebd;
        white-space: nowrap;
        &:hover {
          background-color: #f1f2f7;
        }

        &:has(input[type="checkbox"]:focus) {
          border: 1px solid #353ebd;
        }
        input[type="checkbox"] {
          appearance: none;
        }

        &:has(input[type="checkbox"]:checked) {
          background-color: #353ebd;
          color: #fff;
        }
      }
    }
  }
}

.help-contents-heading-list-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  background-color: #f1f2f7;
  .help-contents-heading-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    color: #1d1c1a;
  }

  .help-contents-heading-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 8px;
    li {
      display: inline-flex;
      align-items: center;
      position: relative;
      padding-left: 1rem;
    }
    li:before {
      position: absolute;
      left: 0;
      content: "•";
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 160%;
      margin-right: 8px;
      color: #353ebd;
    }
    a {
      padding: 4px;
      font-size: 16px !important;
    }
  }
}

.help-content-detail-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1000px;
  padding: 24px 0px 40px;
  margin: 0 auto;
  .help-content-detail-box {
    display: flex;
    gap: 60px;
    .help-content-wrapper {
      display: flex;
      flex-direction: column;
      width: 680px;
      gap: 40px;
    }
  }
  .help-return-to-top {
    color: #353ebd;
    font-feature-settings:
      "liga" off,
      "clig" off;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;
    width: fit-content;
    &:hover {
      text-decoration-line: underline;
    }
    &:focus {
      background-color: #f1f2f7;
    }
    &:active {
      color: #6f3a9a;
    }
  }
}

.custom-menu-list {
  .menu-list a {
    border-radius: 0;
    padding: 0 0 0 10px;
    margin: 20px 4px 20px 0;
    &.is-active {
      padding: 0 0 0 7px;
      background-color: transparent;
      color: $menu-item-color;
      border-left: 3px solid black;
    }
    &:not(.is-active):hover {
      padding: 0 0 0 7px;
      background-color: transparent;
      color: $menu-item-color;
      border-left: 3px solid lightgrey;
    }
  }
}

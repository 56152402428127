.markdown-editor-wrapper {
  flex: 0 0 50%;
  width: 100%;
  z-index: 1;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }

  .markdown-editor-wrapper {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }

  .markdown-editor {
    flex-shrink: 0;
  }

  .markdown-editor-custom-react-mde {
    height: fit-content;
    border: none;
    flex-shrink: 0;

    .markdown-editor-custom-textarea {
      flex-shrink: 0;
      resize: none;
      flex-grow: 1;
      background: #ffffff;
      border: 1px solid #949cb0;
      padding: 8px;
      font-family: "Hiragino Sans";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #262b38;
      margin-right: -1px;
      width: 100%;
      border-bottom-left-radius: 4px;
      box-sizing: border-box;

      &:hover {
        border: 1px solid #5f636d;
      }

      &:focus,
      &:focus-visible {
        border: 1px solid #353ebd;
        outline: unset;
        box-shadow: 0px 0px 5px #353ebd;
      }

      &.disabled {
        background: #f1f2f7;
        border-color: #f1f2f7;
        cursor: not-allowed;
      }

      @media (max-width: 768px) {
        border-bottom-right-radius: 4px;
      }
    }

    textarea[aria-invalid="true"].markdown-editor-custom-textarea-error {
      border: 1px solid #de3226;
      background: #fff5f6;
    }

    textarea[aria-invalid="true"].markdown-editor-custom-textarea-error:hover {
      border: 1px solid #b1110c;
      background: #fff5f6;
    }

    textarea[aria-invalid="true"].markdown-editor-custom-textarea-error:focus {
      border: 1px solid #de3226;
      box-shadow: 0px 0px 5px 0px rgba(222, 50, 38, 0.7);
    }

    .markdown-editor-custom-textarea-success {
      border: 1px solid #3aa393;
      background: #fff;
    }

    .markdown-editor-custom-textarea-success:hover {
      border: 1px solid #258675;
      background: #fff;
    }

    .markdown-editor-custom-textarea-success:focus {
      border: 1px solid #3aa393;
      box-shadow: 0px 0px 5px 0px #3aa393;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .markdown-editor-error-container {
    display: flex;
    flex-direction: column;
    color: #b1110c;
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .markdown-editor-custom-toolbar::-webkit-scrollbar-track {
    display: block;
    height: 20px;
  }

  .markdown-editor-custom-toolbar {
    border: 1px solid #949cb0;
    margin-bottom: -1px;
    border-radius: 0;
    box-sizing: border-box;
    border-top-left-radius: 4px;
    background-color: #f1f2f7;
    @media (max-width: 768px) {
      border-top-right-radius: 4px;
    }
    ul {
      padding: 10px !important;
      display: inline-flex !important;
      padding: 8px !important;
      align-items: center !important;
      flex-grow: 1;
      width: 100%;
      flex-wrap: wrap !important;
      gap: 8px;

      li {
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Hiragino Sans";
        font-style: normal;
        font-weight: 600 !important;
        white-space: nowrap;
        flex-grow: 0;
        font-size: 12px;
        white-space: nowrap;
        margin: 0 !important;

        button {
          border-radius: 4px !important;
          border: 1px solid #949cb0 !important;
          background: #fff !important;
          padding: 8px !important;
          height: 36px !important;

          span {
            font-weight: 600;
            font-size: 12px;
          }

          &:focus {
            border-radius: 4px;
            border: 1px solid #353ebd;
            background: #fff;
            box-shadow: 0px 0px 5px 0px #353ebd;
          }

          &:hover {
            border-radius: 4px;
            border: 1px solid #5f636d !important;
            background: #fff;
          }
        }

        svg {
          width: 16px;
          height: 16px;
        }

        span {
          display: inline-flex;
          align-items: center;
          gap: 4px;
        }
      }
    }
  }

  .markdown-editor-preview-header {
    font-weight: 600;
    font-size: 14px;
    box-sizing: border-box;
    border-bottom: 1px solid #949cb0;
    flex-shrink: 0;
    border: 1px solid #949cb0;
    margin-left: -1px;
    margin-bottom: -1px;
    border-top-right-radius: 4px;
    .markdown-editor-preview-header-contents {
      display: inline-flex;
      padding: 15.5px;
      width: 100%;
    }
    @media (max-width: 768px) {
      border-top: 1px solid #949cb0;
      border-top-left-radius: 4px;
    }
  }

  .markdown-editor-char-count {
    font-family: "Hiragino Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    letter-spacing: 1px;
    color: #262b38;
  }

  .markdown-editor-preview-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 50%;
    .markdown-editor-preview {
      overflow: auto;
      padding: 12px;
      box-sizing: border-box;
      height: 300px;
      border-bottom-right-radius: 4px;
      border: 1px solid #949cb0;
      margin-left: -1px;
      box-sizing: border-box;
      height: 300px;
      @media (max-width: 768px) {
        border-top: none;
        border-left: 1px solid #949cb0;
        border-bottom-left-radius: 4px;
        margin-top: 1px;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

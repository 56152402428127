.search-results-container {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  padding: 40px 0px;
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  .search-results-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .search-results-box-title {
      color: #1d1c1a;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
      padding: 8px 0px;
      border-bottom: 1px solid #b2b8c8;
      width: 100%;
    }
    .search-results-item-box {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .search-results-item {
        display: flex;
        padding-bottom: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        border-bottom: 1px solid #b2b8c8;

        .search-result-product {
          color: #5f636d;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 160%; /* 22.4px */
        }
        .search-result-link {
          width: 100%;
          display: inline-flex;
          flex-direction: column;
          gap: 4px;
          text-align: left;
          &:hover {
            background-color: #f8f9fc;
          }
          &:focus {
            border-radius: 4px;
            border: 1px solid #353ebd;
          }
          .search-result-title {
            color: #353ebd;
            font-feature-settings:
              "liga" off,
              "clig" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%; /* 25.6px */
          }
          .search-result-content {
            color: #1d1c1a;
            font-feature-settings:
              "liga" off,
              "clig" off;
            font-size: 16px;
            font-style: normal;
            line-height: 160%;
            text-align: start;
          }

          .search-result-tag {
            display: inline-flex;
            gap: 8px;
            align-items: center;
            span {
              padding: 4px;
              gap: 8px;
              border-radius: 4px;
              background: #f1f2f7;
            }
          }
          .search-result-highlight {
            background-color: #fff6a8;
            font-weight: 600;
          }
        }
      }
    }
  }
}

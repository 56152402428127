.help-not-found-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  padding: 24px 0 40px;
  .help-not-found-title {
    color: #1d1c1a;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 51.2px */
  }
  .help-not-found-description {
    color: #000;
    text-align: center;
    font-feature-settings:
      "liga" off,
      "clig" off;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%; /* 25.6px */
  }
}

table.c-border {
  thead {
    th {
      border-top: 1px solid $border;
      border-bottom: 1px solid $border;
    }
  }

  tbody {
    td {
      border-bottom: 1px solid #f2f2f2;
    }
  }
}

.payeasy {
  .columns:first-child {
    border-top: 1px solid #b2b8c8;
  }

  .columns {
    border-bottom: 1px solid #b2b8c8;

    .column:first-child {
      border-right: 1px solid #b2b8c8;
    }
  }
}

.help-navigator-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 260px;
  flex-shrink: 0;
  .category-title {
    span {
      display: inline-flex;
      align-items: center;
    }
    svg {
      flex-shrink: 0;
    }
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    padding: 8px;
    border-bottom: 1px solid #b2b8c8;
  }

  .category-children {
    list-style: inside;
    color: #353ebd;
    padding: 8px;
    li {
      display: list-item;
      align-items: center;
      margin-left: 1rem;
      text-indent: -1rem;
    }
    li::marker {
      content: "・";
      font-size: 1.2em;
    }
    .contents-title {
      color: #353ebd;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 160%;
    }

    .contents-title:focus {
      color: #353ebd;
      background-color: #f1f2f7;
    }

    .contents-title:active {
      color: #0f1192;
      text-decoration: underline #0f1192;
    }

    .contents-title:hover {
      text-decoration: underline #353ebd;
    }
  }
}

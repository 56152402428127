.help-top-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 0 auto;
  width: 1000px;
  padding: 24px 0px;
  .help-category-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .help-troubleshooting-content {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .help-troubleshooting-link {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
    .help-category-header {
      display: flex;
      padding-bottom: 8px;
      align-items: center;
      gap: 8px;
      border-bottom: 1px solid #b2b8c8;
      color: var(--text-default, #1d1c1a);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
    }
  }
  .help-content-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
  .disclaimer-text {
    color: #5f636d;
  }
}

.help-rich-text-render {
  width: 100%;
  display: flex;
  flex-direction: column;
  p {
    margin-top: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    line-height: 1.625;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 1.6;
    font-weight: 700;
    scroll-margin-top: 55px;
  }
  h1 {
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  h2 {
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    padding-bottom: 0.5rem;
    font-size: 1.5rem;
    border-bottom: 1px solid #b2b8c8;
  }
  h3 {
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    padding: 0.5rem 0.5rem 0.5rem 1.25rem;
    font-size: 1.25rem;
    border-left: 4px solid #353ebd;
  }
  h4 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  h5 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
  }
  ul {
    list-style: disc !important;
    li {
      margin-left: 1rem;
      margin-top: 0.5rem;
    }
  }
  th,
  td {
    padding: 0.5rem;
  }

  a {
    color: #353ebd;
    font-feature-settings:
      "liga" off,
      "clig" off;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;
    &:hover {
      text-decoration-line: underline;
    }
    &:focus {
      background-color: #f1f2f7;
    }
    &:active {
      color: #6f3a9a;
    }
  }
}

@import "~bulma/sass/utilities/initial-variables";

$modal-card-head-background-color: white;
$modal-card-head-radius: 3px;

// iPadの画面幅を下回ったらmobile表示に切り替わるように指定
$tablet: 768px;
$navbar-breakpoint: $tablet;

// GCDK colors
// ref. https://www.figma.com/file/owL864MT9opCWril2R7FA1/%5BGuideLine%5Dconsole?node-id=4-245&t=eygwseUobIo6rs1p-0
$b500: #353ebd;
$p700: #6a3a9a;
$n40: #f1f2f7;

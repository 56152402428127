.markdown-preview-container h1,
.markdown-preview-container h2,
.markdown-preview-container h3,
.markdown-preview-container a,
.markdown-preview-container ul,
.markdown-preview-container ol,
.markdown-preview-container p,
.markdown-preview-container p:not(:last-child),
.markdown-preview-container ol:not(:last-child),
.markdown-preview-container ul:not(:last-child),
.markdown-preview-container h3:not(:first-child) {
  margin: 0;
}

.markdown-preview-container p {
  white-space: pre-wrap;
}

.markdown-preview-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.markdown-preview-container h2 {
  color: #1d1c1a;
  border-left: 4px solid #454ab8;
  text-align: left;
  padding-left: 24px;
  font:
    32px/56px Meiryo,
    Arial,
    system-ui,
    sans-serif;
}

.markdown-preview-container h3 {
  color: #1d1c1a;
  text-align: left;
  font:
    bold 24px/42px Meiryo,
    Arial,
    system-ui,
    sans-serif;
}

.markdown-preview-container ol {
  list-style-type: decimal;
}

.markdown-preview-container ol li,
.markdown-preview-container ul li {
  margin-left: 1.5em;
}

.markdown-preview-container ol li + li,
.markdown-preview-container ul li + li {
  margin-top: 0.5em;
}

.markdown-preview-container ul {
  list-style-type: disc;
}

// GDKでの表示をプレビューするために　GCDK の Externallink に対して GDKのスタイルを適用
.markdown-preview-container a {
  font:
    18px/31px Meiryo,
    Arial,
    system-ui,
    sans-serif !important;
  text-decoration: underline #454ab8;
}

.markdown-preview-container a:focus {
  outline: 2px solid #454ab8;
  border-radius: 8px;
  background-color: transparent;
}

.markdown-preview-container a svg {
  width: 24px;
}

.markdown-preview-container a:visited {
  text-decoration: underline #7b319a;
  color: #7b319a !important;
}

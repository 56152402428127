@import "./scss/custom-variables";
@import "~bulma/bulma";
@import "~bulma-switch";
@import "~bulma-timeline";
@import "~bulma-extensions/bulma-divider/dist/css/bulma-divider";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/v4-shims.scss";
@import "~@creativebulma/bulma-tooltip/src/sass/index";
@import "./scss/menu";
@import "./scss/layout";
@import "./scss/dropdown-hotfix";
@import "./scss/icon-menu";
@import "./scss/spacer";
@import "./scss/table";
@import "./scss/item-box";
@import "./scss/markdown-preview";
@import "./scss/markdown-editor";
@import "./scss/redoc-container";

.c-content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.c-header-wrapper {
  background-color: #494c4f;
  height: 64px;
}

.c-loading {
  margin: 0 auto;
}

.columns.is-vcentered {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.aside {
  padding: 2em 0.2em 0 1em;
  min-width: 200px;
}

.main {
  padding: 2em 1em 3em 1em;
}

.c-table-contant-wrapper {
  max-width: 1200px;
}

.c-appl-table {
  margin-right: 36px;
  margin-bottom: 36px;
}

.c-tr-selectable {
  cursor: pointer;
}

.c-tr-has-tag {
  line-height: 2em; // for aligning elements to center. bulma tags have `height: 2em`, thus this adjustment is necessary
}

.c-tr-selectable:hover {
  background-color: #e3e3e3;
}

.c-tr-done {
  background-color: #edf0f1;
}

.c-hoverable:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.c-react-tagsinput {
  //background-color: #fff;
  //border: 1px solid #ccc;
  height: 41px;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 12px;
}

.c-react-tagsinput-disabled {
  background-color: whitesmoke;
  border-color: whitesmoke;
  box-shadow: none;
  color: #7a7a7a;

  height: 41px;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 12px;
}

.c-word-break-all {
  word-break: break-all;
}

.modal.has-overflow {
  position: fixed !important;
  overflow: auto !important;

  .modal-background {
    position: fixed !important; // so page stays dark as we scroll
  }

  .modal-content,
  .modal-card,
  .modal-card-body {
    overflow: visible !important;
  }
}

// airbnb react dates

.DateInput_input__small {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.2px;
  padding: 7px 7px 5px;
  background: transparent;
}

.DateRangePickerInput__withBorder {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  //box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  max-width: 100%;
  width: 100%;
}

.stepNumber {
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #ffffff;
  border-radius: 6px 6px 6px 6px;
  background-color: black;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  margin: 25px 5px 0 0;
}

.secretKey {
  color: #000;
  background-color: #f2f2f2;
  border-radius: 6px;
  font-weight: bold;
  padding: 0.5em;
  letter-spacing: 5px;
  display: inline-block;
}

.multi-select {
  --rmsc-primary: #4285f4 !important;
  --rmsc-hover: #f1f3f5 !important;
  --rmsc-selected: #e2e6ea !important;
  --rmsc-border: #ccc !important;
  --rmsc-gray: #aaa !important;
  --rmsc-background: #fff !important;
  --rmsc-spacing: 10px !important;
  --rmsc-border-radius: 4px !important;
  --rmsc-height: 38px !important;
}

hr {
  height: 1px;
  background-color: rgb(219, 219, 219);
}

.c-nabvar-menu-button {
  padding-right: 0px;
}

.tabs-second-level {
  background-color: #f2f2f2;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: left;

    padding: 8px 8px 8px 0px;

    li {
      margin-left: 16px;

      a {
        color: $grey-dark;
      }
    }

    li.is-active a {
      border-bottom-color: #3273dc;
      color: #3273dc;
    }
  }
}

// FIXME: tooltipの文言が切れてしまう問題に対する暫定対応
// https://graffer.atlassian.net/browse/PRODUCTS-6626
.tooltip:hover::before {
  max-width: none !important;
}

.c-use-detail {
  &--wrapper {
    width: max-content;
    min-width: 50%;

    &__half {
      max-width: 50%;
    }
  }

  .label {
    font-size: 0.875em;
    color: hsl(0deg, 0%, 48%);
  }

  .level-item {
    > *:not(:last-child) {
      margin-right: 0.75em;
    }
  }

  &__section {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
}

// GCDKのリンクコンポーネントがexportされていないので、ここでstyleを定義する
a.c__link {
  color: $b500;
  fill: $b500;
  font:
    300 14px/20px Hiragino Sans,
    Meiryo,
    Arial,
    system-ui,
    sans-serif;
  display: inline-flex;
  padding: 8px;
  align-items: center;

  &:hover {
    color: $b500;
    fill: $b500;
    text-decoration: underline $b500;
  }

  &:visited {
    color: $p700;
    fill: $p700;
  }

  &:focus {
    color: $b500;
    background-color: $n40;
  }
}

// グループ一覧画面のスタイル
.group-list-group-name-wrapper {
  display: flex;
}

.group-list-group-name {
  margin-right: 24px;
}

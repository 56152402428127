@use "@graffer-inc/console-design-kit/src/assets/scss/console-variables";

.applications-pagination-footer {
  position: sticky;
  bottom: 0;
  background-color: console-variables.$Wh;
}

.applications-procedure-select,
.applications-sort-select {
  font-size: console-variables.$medium;
}

.item-box {
  border: 1px solid #b8b5b4;
  background-color: #fff;
  padding: 16px 24px 24px 24px;

  @media screen and (max-width: 768px) {
    padding: 16px;
  }

  &-list {
    margin-bottom: 24px;
  }
}

.help-search-function-content-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .help-product-name {
    font-size: 20px;
    font-weight: 600;
  }
  .help-search-function-content {
    display: grid;
    grid-template-columns: repeat(3, 320px);
    gap: 20px;
    .help-search-function-content-box {
      display: flex;
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid #b2b8c8;
      .help-service-name {
        color: #262b38;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 32px */
      }
      .help-search-function-content-link-box {
        display: flex;
        width: 100%;
        height: 224px;
        flex-shrink: 0;
        overflow: hidden;
        flex-direction: column;
        li {
          color: #353ebd;
          position: relative;
          padding-left: 1rem;
        }
        li:before {
          position: absolute;
          left: 0;
          content: "•";
          color: #353ebd;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 160%;
          margin-right: 8px;
        }
        .help-search-function-content-link {
          color: #353ebd;
          font-feature-settings:
            "liga" off,
            "clig" off;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 160%;
        }
      }
      .help-function-list-box {
        display: flex;
        padding-top: 16px;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-top: 1px solid #cfd4e0;
      }
    }
  }
  .help-category-header {
    display: flex;
    padding-bottom: 8px;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid #b2b8c8;
    color: #1d1c1a;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    .help-category-header-icon {
      display: inline-flex;
      align-items: center;
    }
    a {
      scroll-margin-top: 55px;
      color: #1d1c1a !important;
    }
  }
  .help-category-content-title,
  .help-function-list-link {
    color: #353ebd;
    font-feature-settings:
      "liga" off,
      "clig" off;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;
    &:hover {
      text-decoration-line: underline;
    }
    &:focus {
      background-color: #f1f2f7;
    }
    &:active {
      color: #6f3a9a;
    }
  }
}

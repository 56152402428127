@use "@graffer-inc/console-design-kit/src/assets/scss/console-variables";

.customer-reviews-pagination-footer {
  position: sticky;
  bottom: 0;
  background-color: console-variables.$Wh;
}

.review-stars-container {
  display: inline-flex;
  align-items: center;
  color: console-variables.$B500;
}

.customer-reviews-review {
  display: inline-flex;
  align-items: center;
  gap: console-variables.$small;
}

.customer-reviews-procedure-select {
  font-size: console-variables.$medium;
}

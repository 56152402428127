.help-notification-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .help-notification-header {
    display: flex;
    padding-bottom: 8px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: #1d1c1a;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    .help-category-header-icon {
      display: inline-flex;
      align-items: center;
    }
    a {
      scroll-margin-top: 55px;
      color: #1d1c1a !important;
    }
  }

  .help-notification {
    height: 160px;
    overflow: scroll;
    padding: 20px;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #cfd4e0;
  }
}

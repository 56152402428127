.setting-menu-icon path.cls-1 {
  //fill: red;
}
.smart-apply-icon path.cls-1 {
  fill: #468a82;
}
.survey-icon path.cls-1 {
  fill: #634a8c;
}
.ttzk-master-icon path.cls-1 {
  fill: #b5ac5b;
}
.booth-reserve-icon path.cls-1 {
  fill: #6495ed;
}
.ttzk-portal-icon path.cls-1 {
  fill: #fa8f5f;
}
.menu-icon:hover path {
  // TODO: サイズの調整は調べて追加する
  //transform: scale(1.1);
  //width: 66px;
  //height: 66px;
  //transform-origin: 50% 50%;
  transition: transform 0.2s ease-in-out;
  opacity: 0.6; /*これを追加！*/
}

.spacer {
  padding: 1rem;

  &.is-large {
    padding: 2rem !important;
  }

  &.is-small {
    padding: 0.5rem !important;
  }
}

.help-template {
  header {
    top: 0;
    position: sticky;
    z-index: 100;
  }
  footer {
    display: flex;
    width: 100%;
    padding: 40px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: sticky;
    top: 100vh;
    width: 100%;
    border-top: 1px solid #b2b8c8;
    box-sizing: border-box;
    background-color: #fff;
    color: #5f636d;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }
}

// adjust for .redoc-container
.redoc-container {
  .number {
    align-items: initial;
    background-color: transparent;
    border-radius: 0;
    display: inline;
    font-size: inherit;
    height: auto;
    justify-content: initial;
    margin-right: 0;
    min-width: auto;
    padding: 0;
    text-align: inherit;
    vertical-align: baseline;
  }

  code {
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    padding: 0;
  }

  h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0.67em 0;
    padding: 0;
  }

  h3 {
    font-size: 1.25em;
    font-weight: bold;
    margin: 0.5em 0;
    padding: 0;
  }

  h4 {
    font-size: 1.125em;
    font-weight: bold;
    margin: 0.5em 0;
    padding: 0;
  }

  pre {
    -webkit-overflow-scrolling: auto;
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    overflow-x: auto;
    padding: 0;
    word-wrap: break-word;
  }
}

.help-feedback-box {
  padding: 16px;
  background-color: #f1f2f7;
  border-radius: 4px;
}

.help-feedback-box-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.help-feedback-box-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  color: #262b38;
}

.help-feedback-button-group {
  display: flex;
  gap: 16px;
}

.help-feedback-box-content-text {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.help-feedback-box-secondary-content {
  margin-top: 8px;
  a {
    padding: 0 !important;
  }
}

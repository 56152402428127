.help-top-header {
  width: 100%;
  background-image: url("../../../../assets/help-header.png");
  display: flex;
  justify-content: center;
  .help-sub-text {
    color: #353ebd;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
  }
  .help-top-title {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    color: #0d0422;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
  }
  .help-top-subtitle {
    display: inline-block;
    background: #1d1c1a;
    color: #fff;
    font-size: 20px;
    border-radius: 4px;
    padding: 0 12px;
  }

  .help-search-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 640px;
    .help-search-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      p {
        color: #1d1c1a;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .help-search-contents {
    display: flex;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
}

.help-product-header {
  display: flex;
  align-items: center;
}

.help-top-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 1000px;
  .help-content-title {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #b2b8c8;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
  }
  .help-content-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-direction: column;
    gap: 40px;
    padding: 0 0 40px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .help-category-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .help-troubleshooting-content {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .help-troubleshooting-link {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .help-category-header {
      display: flex;
      padding-bottom: 8px;
      align-items: center;
      gap: 8px;
      border-bottom: 1px solid #b2b8c8;
      color: var(--text-default, #1d1c1a);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
    }
  }
  .help-content-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
  .help-return-top-link {
    color: #353ebd;
    font-feature-settings:
      "liga" off,
      "clig" off;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;
    width: fit-content;
    &:hover {
      text-decoration-line: underline;
    }
    &:focus {
      background-color: #f1f2f7;
    }
    &:active {
      color: #6f3a9a;
    }
  }
}

@use "@graffer-inc/console-design-kit/src/assets/scss/console-variables";
.application-information-container {
  display: flex;
  gap: console-variables.$medium;
  align-items: flex-start;
  justify-content: space-between;
  .application-information-left-contents {
    > div > p.title.mt-4 {
      margin-top: inherit !important; // TODO: graffer-schemasのFormGenerator側でタイトルにmt-4が指定されているので、それを上書きする（FormGenerator側の修正が必要）
    }
    display: flex;
    flex-direction: column;
    width: fit-content;
  }

  .application-information-right-contents {
    display: flex;
    align-items: center;
    gap: console-variables.$medium;
  }
}

.help-category-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .help-troubleshooting-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .help-troubleshooting-link {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .help-category-content {
    gap: 4px;
    display: grid;
    align-items: center;
    li {
      color: #353ebd;
      position: relative;
      padding-left: 1rem;
    }
    li:before {
      position: absolute;
      left: 0;
      content: "•";
      color: #353ebd;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 160%;
      margin-right: 8px;
    }
    .help-category-link {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .help-category-content-columns-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .help-category-content-columns-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .help-search-function-content {
    display: grid;
    grid-template-columns: repeat(3, 320px);
    gap: 20px;
  }

  .help-category-header {
    display: flex;
    padding-bottom: 8px;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid #b2b8c8;
    color: var(--text-default, #1d1c1a);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    .help-category-header-icon {
      display: inline-flex;
      align-items: center;
    }
    a {
      scroll-margin-top: 55px;
      color: #1d1c1a !important;
    }
  }
  .help-category-content-title {
    color: #353ebd;
    font-feature-settings:
      "liga" off,
      "clig" off;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;
    &:hover {
      text-decoration-line: underline;
    }
    &:focus {
      background-color: #f1f2f7;
    }
    &:active {
      color: #6f3a9a;
    }
  }
}
